import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

class SlickSlider extends Component {
    render() {
        const settings = {
            buttons: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 5000,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ],
            ...this.props.customSettings
        };
        return <div className="slider-tow-component">
            <Slider {...settings}>{this.props.children}</Slider>
        </div>;
    }
}

SlickSlider.propTypes = {
    customSettings: PropTypes.object,
    children: PropTypes.node
};

export default SlickSlider;